/*
-------------------------------- 
CONTACT PAGE
--------------------------------
*/

/*

Intro
--------------------------------
*/

.contact_section_intro{
    height:30vh;
    width:100vw;
    @include flex-center-fix();
    position:relative;
    z-index:1;

    @include breakpoint(sm) {
        height:54vh;
    } 
    

    .contact_section_inner{
        padding-top:var(--space-xl);
        @include flex-center-fix();
        position:relative;
        gap:var(--space-md);

        .title{
          h1{
            &:last-child{
                display: flex;
                justify-content: center;
                align-items: flex-end;
                gap: 0.8em;
            }
          }

            .icon{
                width: fit-content;
                height: auto;
                margin-left: -0.8em;
                display: inline-block;
                padding-bottom: 0.1em;
                position: absolute;

                lottie-player{
                    height: 1em;
                    width: 1em;
                    max-width: 1em;
                    max-height: 1em;
                }

            }
        }
    
    }


}


.page_down{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;

    .page_down_marker{
        width: 50px;
        position: absolute;
        height: 50px;
        top: -2.3em;
        display: flex;
        z-index: 999;
        background-color: var(--white-100);
        border-radius: 90px;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 20px #0b0b0b0a;

        &:after{
            content: url("data:image/svg+xml;utf8,<svg viewBox=%270 0 13 8%27 width=%2713%27 height=%278%27 xmlns=%27http://www.w3.org/2000/svg%27><g><path fill-rule=%27evenodd%27 clip-rule=%27evenodd%27 d=%27M0.798419 0.422977C0.400527 0.813445 0.400527 1.44652 0.79842 1.83698L5.77955 6.72516C6.17744 7.11563 6.82256 7.11563 7.22045 6.72516L12.2016 1.83698C12.5995 1.44652 12.5995 0.813444 12.2016 0.422977C11.8037 0.0325096 11.1586 0.0325097 10.7607 0.422977L6.5 4.60415L2.23932 0.422977C1.84142 0.0325101 1.19631 0.0325101 0.798419 0.422977Z%27 fill=%27black%27/></g></svg>");
            background-position: center center;
        }
    }
    
}

/*

Form
--------------------------------
*/


/*
-------------------------------- 
FORMS COMPONENT
--------------------------------
*/



.contact_form{
    display: flex;
    flex-direction: column;
    gap: 3em;
  }
  
  // Default
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  
  input,
  input[type="search"],
  input[type="text"],
  input[type="email"],
  input[type="checkbox"],
  textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0px;
    border: none;
    background: transparent;
    outline: none;
    color: inherit;
  
    &:focus, &:active{
      color: inherit;
    }
  
  }
  
  textarea {
    resize: none;
    border: 1px solid var(--grey-300);
    border-radius: 0.5em;
    width: 100%;
    padding: var(--space-sm);
  
    &:hover{
      border-color: var(--black-100);
    }
  }
  
  
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }
  
  button,
  input[type="submit"] {
    cursor: pointer;
    background: none;
  }
  
  .wpcf7-form-control-wrap{
    position:relative;
    width: fit-content;
  }
  
  input[type="search"],
  input[type="text"],
  input[type="email"] {
    width: fit-content;
    position: relative;
    width: 100%;
  }
  
  
  
  .wpcf7-form-control-wrap{
    padding-top:var(--space-xl);
    padding-bottom: var(--space-sm);
    .input-line {
      height: 1px;
      background: var(--grey-300);
      width: 100%;
      position:relative;
    
      &:after {
        content: "";
        position: absolute;
        height: 1px;
        width: 100%;
        bottom: 0px;
        left: 0;
        background: var(--black-100);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 1.2s $easeSmooth;
      }
    }
  
    &:hover {
      .input-line {
        &:after {
          transform: scaleX(1);
          transform-origin: left;
        }
      }
    }
  }
  
  
  
  
  
  // Message style
  
  .wpcf7 form .wpcf7-response-output {
    
    padding: 0.2em 1em;
    border: 2px solid var(--blue); /* Blue */
  }
  
  .wpcf7 form.init .wpcf7-response-output {
    display: none;
  }
  
  .wpcf7 form.sent .wpcf7-response-output {
    border-color: var(--green); /* Green */
  }
  
  .wpcf7 form.failed .wpcf7-response-output,
  .wpcf7 form.aborted .wpcf7-response-output {
    border-color: var(--red); /* Red */
  }
  
  .wpcf7 form.spam .wpcf7-response-output {
    border-color: #f56e28; /* Orange */
  }
  
  .wpcf7 form.invalid .wpcf7-response-output,
  .wpcf7 form.unaccepted .wpcf7-response-output {
    border-color: #ffb900; /* Yellow */
  }
  
  .wpcf7-not-valid-tip {
    color: var(--red);
    font-size: 1em;
    font-weight: normal;
    display: block;
  }
  
  
  
  // Checkbox style
  
  $form-grid-web: (
    4: repeat(4, 1fr),
    3: repeat(3, 1fr),
    2: repeat(2, 1fr)
    
  );
  
  .wpcf7-checkbox,
  .wpcf7-radio {
    display: grid;
    column-gap: var(--space-lg);
    row-gap: 1em;
    
    @each $grid, $template in $form-grid-web {
      .form-grid-#{$grid} & {
        grid-template-columns: $template;
      }
    }
  
    
  
    input + span {
      display: inline-block;
      border: 1px solid var(--black-100);
      padding: 1.4em 1.4em;
      font-size: var(--text-sm);
      border-radius: 5px;
      position: relative;
      cursor: pointer;
      width: 100%;
      text-align: center;
      transition: all 0.24s ease;
  
      &:hover {
        background: var(--black-100);
        color: var(--white-100);
      }
    }
     
    input:checked + span {
      transition: all 0.24s ease;
      background: var(--black-100);
      color: var(--white-100);
    }
  }
  
  
  
  
  
  
  