/*
-------------------------------- 
Youtube
--------------------------------
*/

.block_youtube {
    --aspect-ratio: 16/9;
    position: relative;
    overflow: hidden;
    border-radius: 1rem;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  
    iframe {
      position: absolute;
      width: 100%!important;
      height: 100%!important;
      }
  
}


.block_text_text{
  .block_text_text_inner{
    @include flex-between-start();
    position:relative;
  }
}

/*
-------------------------------- 
Marquee
--------------------------------
*/

.block_marquee_scroll {
  position: relative;
  z-index: 3;
  overflow: hidden;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  -webkit-mask-image: linear-gradient(to right, hsla(0, 0%, 0%, 0), hsl(0, 0%, 0%) 20%, hsl(0, 0%, 0%) 80%, hsla(0, 0%, 0%, 0)), linear-gradient(to left, hsla(0, 0%, 0%, 0), hsl(0, 0%, 0%) 20%, hsl(0, 0%, 0%) 80%, hsla(0, 0%, 0%, 0));
  mask-image: linear-gradient(to right, hsla(0, 0%, 0%, 0), hsl(0, 0%, 0%) 20%, hsl(0, 0%, 0%) 80%, hsla(0, 0%, 0%, 0)), linear-gradient(to left, hsla(0, 0%, 0%, 0), hsl(0, 0%, 0%) 20%, hsl(0, 0%, 0%) 80%, hsla(0, 0%, 0%, 0));
   
  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  .marquee-content {
    display: flex;
    align-items: center;
    padding: 33vh 0;
    text-align: center;
  }

  .marquee-carousel.marquee-carousel-1 {
    margin-right: -300px;
    margin-bottom: var(--space-lg);
  }


  .marquee-items {
    display: flex;
    position: relative;
    width: 100%;
    height: 4em;
    transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1) !important;

    img {
      width: 70%;
      height: auto;
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);

    }
  }

  .marquee-item {
    flex-shrink: 0;
    width: 13em;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 0.5em;

 
  }
}


/*
-------------------------------- 
Bloc titre et accordeon
--------------------------------
*/

.inner_ac {
  display: flex;
  flex-direction: column;
  gap: var(--space-md);
  width: 100%;

  .accordeon{
    &.ac {
      box-sizing: border-box;
      border-bottom: 1px solid var(--grey-200);
  
      .ac-header {
        color:var(--black-100);
        margin: 0;
        padding: 0;
      }
      
      .ac-trigger {  
        text-align: left;
        width: 100%;
        padding: var(--space-lg) 0;
        cursor: pointer;
        background-color: transparent;
        transition: color .25s ease;
        position: relative;
        text-decoration: none;
        margin: 0;
        border: 0;
        
        &::after {
          content: "+";
          text-align: center;
          width: 15px;
          -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
          position: absolute;
          right: var(--space-lg);
          top: 50%;
        }
        
      }
      
      .ac-panel {
        overflow: hidden;
        transition-property: height, visibility;
        transition-timing-function: ease;
        
        .ac-text {
          padding: var(--space-lg) 0;
          margin: 0;
        }
      }
      
      &.js-enabled {
        .ac-panel {
          visibility: hidden;
        }
      }
      
      &.is-active {
        .ac-panel {
          visibility: visible;
        }
        
        > .ac-header .ac-trigger::after {
          content: "–";
        }
      }
    }
    
  }
}


/*
-------------------------------- 
Line project
--------------------------------
*/

.line-project {
  box-sizing: border-box;
  padding-top:var(--space-lg);
  padding-bottom:var(--space-lg);


  // Header class
  .ac-header {
    color:var(--black-100);

    &.item_2{
    /* margin: 0; */
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;


    @include breakpoint(sm) {
      grid-template-columns: 1.19fr 1fr 0.15fr;
  } 

    }

    &.item_3{
      margin: 0;
      display: grid;
      grid-template-columns: 0.15fr 1fr 0.15fr;
      padding: 0;
      gap:var(--space-lg);
      align-items: start;

      @include breakpoint(sm) {
        grid-template-columns: 1.19fr 1fr 0.15fr;
      } 
    }

        img{
            width: 40px;
            height:40px;

            @include breakpoint(sm) {
                width: 60px;
                height:60px;
            } 
        }
    
  }

  // Trigger class
  .ac-trigger {
    width: 100%;
    display: block;
    margin-left:auto;
    cursor: pointer;
    background-color: transparent;
    transition: color .25s ease;
    position: relative;
    text-decoration: none;
    border: 0;
  }

  // Panel class
  .ac-panel {
    overflow: hidden;
    transition-property: height, visibility;
    transition-timing-function: ease;
    

    .ac-text {
        padding-top: var(--space-md);
    }
  }

  // Hide content when Javascript is enabled
  &.js-enabled {
    .ac-panel {
      visibility: hidden;
    }
  }

  // Active class
  &.is-active {
    .ac-panel {
      visibility: visible;
    }
  }
  
}


/*
-------------------------------- 
Block pinned img
--------------------------------
*/

.block_pinned,
.block_pinned_process{
  .pinned-wrap {
      overflow: hidden;
      .pinned-inner {
          display: flex;
          flex-direction: column;
          gap:var(--space-xl);
          width: 100%;
          max-width: var(--max-width-xxxl);
          padding-left:var(--component-padding);
          padding-right:var(--component-padding);
          
          @include breakpoint(sm) {
              flex-wrap: nowrap;
              flex-direction: row;
              gap:0;
          }

          .profil_image{
              @include breakpoint(sm) {
                padding-right: var(--space-xl);
            
                  &:nth-of-type(even){
                    -webkit-transform: rotate(1deg);
                    -ms-transform: rotate(1deg);
                    transform: rotate(1deg);
                    transition: transform 0.5s ease;

                    &:hover{
                      -webkit-transform: rotate(0deg);
                      -ms-transform: rotate(0deg);
                      transform: rotate(0deg);
                      transition: transform 0.5s ease;
                    }

                  }

                  &:nth-of-type(odd){
                    -webkit-transform: rotate(-1deg);
                    -ms-transform: rotate(-1deg);
                    transform: rotate(-1deg);
                    transition: transform 0.5s ease;

                    &:hover{
                      -webkit-transform: rotate(0deg);
                      -ms-transform: rotate(0deg);
                      transform: rotate(0deg);
                      transition: transform 0.5s ease;
                    }

                  }
                  
                  &:last-child{
                          padding-right:var(--component-padding);
                  }

              }

              .image {
                      overflow:hidden;
                      border-radius: 0.5rem;
                      width: 100%;
                      height: 25rem;
                      img{
                          height: 100%;
                          width: 100%;
                  }
                      @include breakpoint(sm) {
                          width: 35rem;
                          height: 73vh;
                  }
              }

              .content_container{
                  display:flex;
                  padding-top: var(--space-md);
                  justify-content: space-between;
                  .content {
                      display: flex;
                      flex-direction: column;
                      gap: var(--space-xs);
                  }
              }
          }

          .block_pinned_process_content{
            @include breakpoint(sm) {
              padding-right: var(--space-xl);
          }
            
              
                
                &:last-child{
                        padding-right:var(--component-padding);
                }

                .block_pinned_content{
                  height: 24em;
                  border-radius: 0.5em;
                  width: 100%;
                  display: flex;
                  padding: var(--space-lg);
                  flex-direction: column;
                  justify-content: space-between;
                  position: relative;
                  overflow: hidden;
                  z-index: 1;

                  div{
                    display: flex;
                    align-items: center;
                    gap: var(--space-md);
                    justify-content: flex-start;
                    flex-direction: row;
                  }
           
                    @include breakpoint(sm) {
                        width: 40rem;
                        height: 24rem;
                }
            }
                
          }

      }
  }
}


/*
-------------------------------- 
Block contact
--------------------------------
*/

.block_title_card{
  .block_title_card_content{
     .card{
      width: auto;
      height: auto;
      padding: var(--space-xl);
      background-color: var(--white-100);
      border-radius: 0.5em;
      color: var(--black-100);
      border: 1px solid var(--grey-200);
      display: flex;
      gap: var(--space-md);
      flex-direction: column;
     } 
}   
}


.flex-gap-xs{
  display: flex;
  flex-direction: row;
  align-items: center;
}


.block_client_review {
  height: 30vh;
  background: var(--black-100);
  border-radius: 0.5rem;
}


.block_img {
  overflow: hidden;
  border-radius: 0.5em;
}



/*
-------------------------------- 
Block logo vignette
--------------------------------
*/

.block_logo_content {
  background: var(--white-200);
  padding: var(--space-xl);
  border-radius: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;

  img{
    width: 70%;
    height: auto;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }
}




.b-r{
  border-radius:0.5em;
  overflow: hidden;
}


.flex{
  @include flex-between();
}

.flex_bs{
  @include flex-between-start();
}

.flex_b{
  @include flex-between();
}