/*
-------------------------------- 
ARTICLE SINGLE
--------------------------------
*/

#single-article{
    .single_article {
        .single_article_inner{
            display:flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap:var(--space-lg);

            @include breakpoint(sm) {
                justify-content: space-between;
                align-items: center;
                flex-direction: row;
            }  

        }
    }

    .single_article_content{ 
        .single_article_content_inner{
            display:grid;
            height: fit-content;
            grid-template-columns: repeat(12,1fr);
            gap: var(--space-lg);
        
            #sticky_block{
                grid-column: 1/span 12;
                height: fit-content;
        
                @include breakpoint(sm) {
                    grid-column: 1/span 4;
                }
        
                .single_nav{
                    display: flex;
                    gap: var(--space-md);
                    flex-direction: column;
                    overflow: hidden;
                    position:relative;
                    background-color: var(--grey-100);
                    border-radius:0.5rem;
                    padding:var(--space-lg);
        
                    .progress{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 0.3em;
                    }
        
                    progress {
                        -webkit-appearance: none;
                      }
                      
                      ::-webkit-progress-value {
                        background-color: var(--principal-color);
                      }
                      
                      ::-webkit-progress-bar {
                         background-color: var(--white-200);
                      }
        
                }
        
                .share-container{
                    display: flex;
                    gap: var(--space-md);
                    margin-top: var(--space-lg);
                    flex-direction: row;
                    position: relative;
                    background-color: var(--grey-100);
                    border-radius: 0.5rem;
                    padding: var(--space-lg);
                    justify-content: space-between;
                    align-items: center;
        
                    .social-links {
                        display: flex;
                        gap: var(--space-md);
        
                        li{
                            background-color: var(--white-100);
                            padding:5px 10px 2px 10px;
                            border-radius: 100px;
        
                            &:hover{
                                background-color: var(--principal-color); 
                            }
        
                        }
                    }
                }
            }
        
            .single_content{
                grid-column: 1/span 12;
                height: fit-content;
                display: block;
                position:relative;
        
                @include breakpoint(sm) {
                    grid-column: 5/span 8;
                }
            }
        }  
    }
}

.related-section{
    width: 100%;
    .title{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

/*
-------------------------------- 
STUDY CASE SINGLE
--------------------------------
*/

#single-project{
    .single_study_case {
        .single_study_case_inner{
            display:flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap:var(--space-lg);

            @include breakpoint(sm) {
                justify-content: space-between;
                align-items: center;
                flex-direction: row;
            }  

        }
    }

    .single_study_case_content{ 
        .single_study_case_content_inner{
            display:grid;
            height: fit-content;
            grid-template-columns: repeat(12,1fr);
            gap: var(--space-lg);
        
            #sticky_block{
                grid-column: 1/span 12;
                height: fit-content;
                position:relative;
                z-index:1;
        
                @include breakpoint(sm) {
                    grid-column: 1/span 4;
                }

                .sticker{
                    position:absolute;
                    right:3em;
                    top:2em;
                    z-index:2;

                    svg{
                        width: 7em;
                        height: 7em;
                    }
            
                    @include breakpoint(sm) {
                        right: 1em;
                        top: 0em;

                        svg{
                            width: 4em;
                            height: 4em;
                        }
                    } 
                }
        
                .single_nav{
                    display: flex;
                    gap: var(--space-lg);
                    flex-direction: column;
                    overflow: hidden;
                    position:relative;
                    background-color: var(--white-200);
                    border-radius:0.3rem;
                    padding:var(--space-lg);

                    .logo_img{
                        width: 8em;
                        height: 8em;

                        @include breakpoint(sm) {
                            width: 4em;
                            height: 4em; 
                        }
                    }

                    .author{
                        display: flex;
                        align-items: center;
                        align-content: center;
                        flex-direction: row;
                        gap: var(--space-lg);
                        background: var(--white-100);
                        border-radius: 0.5rem;
                        padding: var(--space-md);

                        .author_img{
                            width: 6em;
                            height: 6em;
                            border-radius: 0.3rem;
                            overflow: hidden;
                        }   
                    }
                }
        
            }
        
            .single_content{
                grid-column: 1/span 12;
                height: fit-content;
                display: block;
                position:relative;
        
                @include breakpoint(sm) {
                    grid-column: 5/span 8;
                }
            }
        }  
    }
}

/*
-------------------------------- 
EXPERTISE SINGLE
--------------------------------
*/

#single-expertise{
    lottie-player{
    position: absolute;
    top: -1em;
    left: 17em;
    width: 6em;
    z-index:2;
    height: 6em;
    }
}




