/*
-------------------------------- 
Z-INDEX
--------------------------------
*/
$loader_level: 10000000002;
$curtain_level_two: 10000000001;
$curtain_level_one: 10000000000;
$header_level: 1000;
$megamenu_level: 960;
$megamenu_back_level: 950;
$navFullscreen_level: 940;
$cookie_level: 900;
$page_up_level: 100;