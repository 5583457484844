/*
-------------------------------- 
COOKIES COMPONENT
--------------------------------
*/
#audit_section{
  .audit_banner { 
    position: fixed;
    bottom: calc(1.4 * var(--space-unit));
    right: calc(1.4 * var(--space-unit));
    z-index: $cookie_level;
    transition:transform 1s ease;
    cursor:pointer;
    display: none;

    @include breakpoint(sm) {
       display:initial;
    } 

    &:hover{
        transform:scale(1.1);
        transition:transform 1s ease;
    }

    .audit_banner_inner { 
        display:flex;
        align-items: center;
        justify-content: center;

        svg#audit{
            width: 12.5em;
            position:absolute;

            @include breakpoint(sm) {
                width: 7.8em;
            } 

            #free-audit{ 
                transform-origin: center center;
                .audit-1 {fill:var(--blue);}
                .audit-2{fill:var(--white-100);}
            }
        
        }

        lottie-player{
          width: 8em;
          height: 8em;
        }
        
    

        @include breakpoint(sm) {

            .cookie-container{
                flex-direction: initial;
            }
        }

    }
}
}



.easteregg {
    z-index: 8;
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    overflow: hidden;
    display:none;

  
    &__image {
      width: 19rem;
      height: 19rem;
      position: absolute;
      top: auto;
      bottom: 5rem;
      left: auto;
      right: 6.9rem;
      transform: scale(0) ;

      img{
        object-fit: initial;
      }
  
 
  
      &.is-1 {
        top: 9.3rem;
        bottom: auto;
        left: -2rem;
        right: auto;

        img{
          transform: rotate(-10deg);
        }
      }
  
      &.is-2 {
        z-index: 3;
        top: -5.8rem;
        bottom: auto;
        left: 31.1rem;
        right: 0%;

        img{
          transform: rotate(8deg);
        }
      }
  
      &.is-3 {  
        bottom: -2rem;
        left: auto;
        right: 16.4rem;

        img{
          transform: rotate(-8deg);
        }
      }
  
      &.is-4 {
        top: -2rem;
        bottom: 0;
        left: auto;
        right: 28.3rem;

        img{

        }
      }
  
      &.is-5 {
        top: 1rem;
        bottom: 0;
        left: auto;
        right: -0.4rem;

        img{
          transform: rotate(6deg);
        }
      }
  
      &.is-6 {
        bottom: 1.8rem;
        left: 4.1rem;

        img{
          transform: rotate(12deg);
        }
      }
  
    
    }
  }


