/*
-------------------------------- 
Blog page
--------------------------------
*/


/* News archive -------------------------------- */

#archive-news,
#archive-category{
    .stickers{
        .sticker{

            &:nth-child(1){
                position:absolute;
                right: 1em;
                top: 60em;
                z-index: 2;
               
                svg {
                    height: 7em; 
                    width: 7em; 
                }

                @include breakpoint(sm) {
                    right: 5.5em;
                    top: 35em;

                    svg {
                        height: 4.5em; 
                        width: 4.5em; 
                    }
                } 
            }

            &:nth-child(2){
                position:absolute;
                left: 1em;
                top: 40em;
                z-index: 2;

                svg {
                    height: 7em; 
                    width: 7em; 
                }

                @include breakpoint(sm) {
                    left: 5.5em;
                    right: initial;
                    top: 26em;

                    svg {
                        height: 4.5em; 
                        width: 4.5em; 
                    }
                } 
            }

            &:nth-child(3){
                position:absolute;
                right: 16em;
                bottom: 27em;
                left:initial;
                z-index: 2;

                svg {
                    height: 7em; 
                    width: 7em; 
                }

                @include breakpoint(sm) {
                    left: 33em;
                    right:initial;
                    bottom: 18em;

                    svg {
                        height: 4.5em; 
                        width: 4.5em; 
                    }
                } 
            }

        }
    }
}

.blog_filter_categories{
    .filters-container {
        display: flex;
        gap: var(--space-sm);
        overflow: auto;
        white-space: nowrap;

        .item-filter {
            background-color: var(--grey-100);
            border:1px solid var(--grey-200);
            padding: 12px 14px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            border-radius: 8px;
            -khtml-border-radius: 8px;
            transition:all 0.5s ease;

            &:hover{
                background-color: var(--black-100);
                color: var(--white-100);
                transition:all 0.5s ease;
            }

        
            &.active {
                background-color: var(--black-100);
                pointer-events: none;
                border:1px solid var(--black-200);
                color: var(--white-100);
            }
        }
    }
}

.card-article{
        .article-content {
            display: flex;
            flex-direction: column;
            gap: var(--space-md);
            margin-top: var(--space-md);
            align-items: flex-start;
    
            .article-content-inner{
                display: flex;
                flex-direction: row;
                text-align: left;
                align-items: center;
                column-gap: 4px;
                justify-content: flex-start;

                @include breakpoint(sm) {
                    column-gap: var(--space-sm);
                  } 
            }
    
        }
}


/* Cas Client archive -------------------------------- */

#archive-projects{
    .stickers{
        .sticker{

            &:nth-child(1){
                position:absolute;
                right: 1em;
                top: 60em;
                z-index: 2;
               
                svg {
                    height: 7em; 
                    width: 7em; 
                }

                @include breakpoint(sm) {
                    right: 5.5em;
                    top: 33em;

                    svg {
                        height: 5em; 
                        width: 5em; 
                    }
                } 
            }

            &:nth-child(2){
                position:absolute;
                left: 1em;
                top: 12em;
                z-index: 2;

                svg {
                    height: 7em; 
                    width: 7em; 
                }

                @include breakpoint(sm) {
                    left: 5em;
                    top: 9em;

                    svg {
                        height: 5em; 
                        width: 5em; 
                    }
                } 
            }

            &:nth-child(3){
                position:absolute;
                left: 16em;
                bottom: 12em;
                z-index: 2;

                svg {
                    height: 7em; 
                    width: 7em; 
                }

                @include breakpoint(sm) {
                    left: 33em;
                    bottom: 5em;

                    svg {
                        height: 5em; 
                        width: 5em; 
                    }
                } 
            }

  

        }
    }
}


.case_study_section{

    .case_study_section_inner{
 
        .title{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: var(--space-xxl);
        }
      
            .case_study{
                width: auto;
                height: auto;
                padding: var(--space-lg) var(--space-lg) var(--space-lg) var(--space-lg);
                background-color: var(--grey-100);
                border-radius: 0.5em;
                border: 1px solid var(--grey-200);
                display: flex;
                position: relative;
                gap: var(--space-md);
                flex-direction: column;

                @include breakpoint(sm) {
                    padding: var(--space-xxl) var(--space-xl) var(--space-xl) var(--space-xl);
                  } 

                

                .logo_img{
                    position: relative;
                    z-index: 1;
                    width: 5em;
                    height: 5em;
                    left:0;
                    top: 0;
                    right:0;
                    border-radius: 0.3em;
                    border: 0px solid var(--white-100);

                    @include breakpoint(sm) {
                        position: absolute;
                        z-index: 1;
                        width: 4.5em;
                        height: 4.5em;
                        left:calc(var(--space-xl) - 8px);
                        top: -1em;
                        right:0;
                        border-radius: 0.3em;
                        border: 8px solid var(--white-100);
                      } 

                }

                .description{
                    display: flex;
                    gap: var(--space-md);
                    flex-direction: column;

                    .btn{
                        width: fit-content;
                        margin-top:var(--space-md);
                    }

               }
           }
       
   }
}



