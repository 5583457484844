/*
-------------------------------- 
HOME PAGE
--------------------------------
*/

/*

Intro
--------------------------------
*/

.home_section_intro{
    height:90vh;
    width:100vw;
    @include flex-center-fix();
    position:relative;
    z-index:1;

    @include breakpoint(sm) {
        height:96vh;
    } 
    

    .home_section_inner{
        padding-top:var(--space-xl);
        @include flex-center-fix();
        position:relative;
        gap:var(--space-md);

        .title{
            h1{
                &:first-child{
                    .line-up{
                        display: flex !important;
                        flex-direction: row;
                        align-items: center;
                        gap: 12px;
                        justify-content: center;
                    }
                }
            }

            .icon{
                svg{
                height: 0.8em;
                width: 0.85em;
                }
            }
        }
        .description{
            opacity:0.8;
        }
        .sticker {
            position: absolute;
            width: fit-content;
            z-index: 1;
            transform:rotate(-8deg);
            top: 3em;
            left: 7em;

            @include breakpoint(sm) {
                top:5em;
                left: 18em;
            } 

 

            svg{
                width: 12em;
                }
        }
    }
}


/*

About
--------------------------------
*/

.home_section_about{
    height:auto;
    width:100vw;
    position:relative;
    z-index:2;
    @include flex-center-fix();
   
    .home_section_about_inner{
        @include flex-center-fix();
        gap: var(--space-lg); 
        position:relative;
        
        .stickers{
            lottie-player{
            &:nth-child(1){
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(-15deg);
    width: 8em;
    height: 8em;
            
        }

                    &:nth-child(2){
    position: absolute;
    top:5em;
    right: 1em;
    transform: rotate(15deg);
    width: 8em;
    height: 8em;
            
        }
    }
    }
}
}


/*

Expertises
--------------------------------
*/

.home_section_expertises{
    height:auto;
    width:100vw;
    position: relative;
    z-index:2;
    @include flex-center();


    .home_section_expertise_content{

        .expertise_content_sticky {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 9vh 0 9vh;
                    height: 40vh;
                    width: 100%;
                    min-width: 100%;
                    max-width: 100%;
                    background-color: var(--black-200);
                    border-radius: 1rem;
                    overflow: hidden;
        }
        
        .expertise_content_text{
            grid-column: 8/span 4;
            position: relative;
            display: flex;
            gap:var(--space-lg);
            flex-direction: column;
            justify-content: center;
            overflow: hidden;
            transition: visibility 0.8s cubic-bezier(0.33, 1, 0.68, 1);

            .benefits{
                .advantage{
                    display: flex;
                    align-items: center;
                    gap: var(--space-sm);
                    padding-bottom:var(--space-sm);
                    border-bottom:1px solid var(--white-100);
                    margin-bottom:var(--space-sm);

                    &:last-child{
                        margin-bottom:0;
                    }
                }
            }
        }
    }

    @include breakpoint(sm) {
        height:100vh;
        padding:0;

        .home_section_expertises_inner{    
            .home_section_expertise_content{
                position: relative;
                opacity:0;
                display:flex;
                animation: fadeOutDown 1s both;
                flex-direction: column;
    
                @include breakpoint(sm) {
                    display: grid;
                    position: absolute;
                    top: 0;
                    left: var(--component-padding);
                    right: var(--component-padding);
                    bottom: 0;
                    grid-template-columns: repeat(12,1fr);
                    grid-template-rows: auto;
                    grid-column-gap: var(--space-lg);
                } 
               
                &.is-active{
                    opacity:1;
                    animation: fadeInUp 1s both;
    
                    .line-up{
                        transform: translateY(0);
                        opacity: 1;
                        transition:all 1s ease;
                    }
                }
    
                .expertise_content_sticky{
                    grid-column: 1/span 6;
                    @include flex-center();
                    
                    --main-transition: transform 0.8s cubic-bezier(0.33,1,0.68,1),opacity 0.4s cubic-bezier(0.33,1,0.68,1);
                    transition: var(--main-transition);
    
                    @include breakpoint(sm) {
                        margin: 12.5vh 0 12.5vh;
                        height: 80vh;
                    } 
    
                    img{
                        width: 20em;
                    }
                }
    

    
            }
    
        }
    } 

 

}


/*

Key number
--------------------------------
*/

.home_section_key_number{
    .home_section_key_number_inner{
        .key_number_content{
            height: 20em;
            border-radius: 0.5em;
            border: 1px solid var(--grey-200);
            width: 100%;
            display: flex;
            padding: var(--space-lg);
            background-color: var(--pink);
            color:var(--black-100);
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            overflow: hidden;
            z-index:1;

            h3,p{
                position:relative;
                z-index:2
            }

            @include breakpoint(sm) {
           
            } 

            .pattern{
                position: absolute;
                top: -4em;
                left: -4em;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                width: 130%;
                height: 130%;
                pointer-events: none;
                z-index: 1;
                opacity: 0.2;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;

                svg{
                    position:relative;
                    fill: transparent;
                    fill:#ffcfcb;
                }
            }
        }
    }
}


/*

Case study
--------------------------------
*/

.home_section_case_study{
    height:auto;
    width:100vw;
    display:flex;
    align-items: center;
    justify-content: center;
    
    .home_section_case_study_inner{
        position:relative;

        .title {
            @include flex-between-start();
        }

    }
}





@keyframes fadeInUp {
    0% {
      visibility: hidden;
      filter: blur(6px);
      opacity: 0;
      transform: translateY(10px);
    }
  
    100% {
      visibility: inherit;
      filter: none;
      opacity: 1;
      transform: none;
    }
}
  
@keyframes fadeOutDown {
      100% {
          visibility: hidden;
          filter: blur(6px);
          opacity: 0;
          transform: translateY(10px);
      }
}














  