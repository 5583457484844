footer {
    position: relative;
    margin-top: 0;
    width: 100%;
    overflow: hidden;
    display: flex;
    color:var(--white-100);
    box-sizing: border-box;
    padding: 0;
    justify-content: center;
    align-items: flex-end;


    .pattern {
    position: absolute;
    top: -20vh;
    left: -20vw;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 170%;
    height: 170%;
    pointer-events: none;
    z-index: -1;
    opacity: 0.4;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

      svg{
        path{fill:var(--black-100);}
        
      }

    @include breakpoint(sm) {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    } 


    svg{
      stroke: var(--black-400);;
    }
    }

    @include breakpoint(sm) {
        margin-top: -100vh;
      } 

    .footer_inner_top{
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        gap: var(--space-lg);

        .footer_cta{
            margin-top:var(--space-lg);
            display: flex;
            gap: var(--space-md);
            flex-direction: column;
            align-items: center;

            @include breakpoint(sm) {
              flex-direction: row;
            }      
        }
    }

    .footer_inner_bottom{
        position:relative;
        margin-top:var(--space-xxxxl);
        display: flex;
        flex-direction: column;
        gap: var(--space-md);
        justify-content: space-between;
        align-items: center;

        @include breakpoint(sm) {
            flex-direction: row;
          } 

        .legal{
            position: relative;
            left: 0;
            right: 0;
            display: flex;
            margin-left: auto;
            margin-right: auto;
            pointer-events: all;
            justify-content: center;
            gap:var(--space-md);

            @include breakpoint(sm) {
              margin-right: initial;
              
              } 
        }  
    }
}