/*
-------------------------------- 
BUTTON COMPONENT
--------------------------------
*/
.js-element {
	display: inline-block;
}

.btn{
	width: fit-content;
}

/*

Button underline
----------------
*/

.underline,
.underline-link,
#breadcrumbs a {
	position: relative;
	color: inherit;
	cursor: pointer;
}

.underline {
		width: fit-content;
		&:after {
			content: "";
			position: absolute;
			height: 1px;
			width: 100%;
			bottom: -1px;
			left: 0;
			background: var(--black-100);
			transform: scaleX(0);
			transform-origin: right;
			transition: transform 1.2s $easeSmooth;
		}

		&:hover {
			&:after {
				transform: scaleX(1);
				transform-origin: left;
			}
		}

		&.is-white {
			&:after {
				background: var(--white-100);
			}
		}
}



.underline-link {
		width: fit-content;
		&:after {
			content: "";
			position: absolute;
			height: 1px;
			width: 100%;
			bottom: -1px;
			left: 0;
			background: var(--black-100);
			transform: scaleX(1);
			transform-origin: left;
			transition: transform 1.2s $easeSmooth;
		}

		&:hover {
			&:after {
				transform: scaleX(0);
				transform-origin: right;
			}
		}

		&.is-white {
			&:after {
				background: var(--white-100);
			}
		}
}



/*

Button primary
--------------
*/


.btn-primary {
    position: relative;
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: var(--text-sm);

    font-family: var(--font-subtitle);
    padding: 1.1em 1.2em 1.1em 1.2em;
    border-radius: 0.5rem;
    overflow: hidden;
    line-height: initial
}

.btn-primary span {
    position: relative;
    z-index: 2
}



.btn-primary {
    /* -webkit-transition:color 0.4s ease,background 0.4s ease,-webkit-transform 0.8s cubic-bezier(0.19, 1, 0.22, 1); */
    transition:color 0.4s ease,background 0.4s ease,-webkit-transform 0.8s cubic-bezier(0.19, 1, 0.22, 1); 
    -o-transition: color 0.4s ease,background 0.4s ease,transform 0.8s cubic-bezier(0.19, 1, 0.22, 1);
    /* transition:color 0.4s ease,background 0.4s ease,transform 0.8s cubic-bezier(0.19, 1, 0.22, 1); */
    /* transition:color 0.4s ease,background 0.4s ease,transform 0.8s cubic-bezier(0.19, 1, 0.22, 1),-webkit-transform 0.8s cubic-bezier(0.19, 1, 0.22, 1); */
}


.btn-primary:after {
    content: "";
    position: absolute;
    bottom: -1%;
    left: -1%;
    width: 102%;
    height: 102%;
    border-radius: 0.5rem;
	background: var(--principal-color);
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    -webkit-transition: -webkit-transform 0.64s cubic-bezier(0.19, 1, 0.22, 1);
    transition: -webkit-transform 0.64s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: transform 0.64s cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 0.64s cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 0.64s cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 0.64s cubic-bezier(0.19, 1, 0.22, 1)
}



.btn-primary:hover:after {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom
}

/*

White
----------------
*/
.btn-primary.b-white {
    background: var(--white-100);
	color:var(--black-100);
}

.btn-primary.b-white:after {
    background: var(--principal-color)
}

.btn-primary.b-white:hover {
    color: var(--black-100);
}


/*

Black
----------------
*/
.btn-primary.b-black {
	background: var(--black-100);
	color:var(--white-100);
}

.btn-primary.b-black:after {
    background: var(--principal-color);
}

.btn-primary.b-black:hover {
    color:var(--black-100);
}


/*

green
----------------
*/
.btn-primary.b-green {
	background: var(--green);
	color:var(--black-100);
}

.btn-primary.b-green:after {
    background: var(--principal-color);
}

.btn-primary.b-green:hover {
    color:var(--black-100);
}


/*

Outline white
----------------
*/

.btn-primary.b-outline-white {
    border:1px solid  var(--white-100);
	color:var(--white-100);
}

.btn-primary.b-outline-white:after {
    background: var(--principal-color)
}

.btn-primary.b-outline-white:hover {
    color: var(--black-100);
	border:1px solid var(--principal-color);
}

/*

Outline black
----------------
*/

.btn-primary.b-outline-black {
	border:1px solid var(--black-100);
	color:var(--black-100);
}

.btn-primary.b-outline-black:after {
    background: var(--principal-color);
}

.btn-primary.b-outline-black:hover {
    color:var(--black-100);
	border:1px solid var(--principal-color);
}


