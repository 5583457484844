/*
-------------------------------- 
FAQ
--------------------------------
*/


#page-faq{
    .stickers{
      .sticker{

          &:nth-child(1){
              position:absolute;
              right: 1em;
              top: 0em;
              z-index: 2;
              @include breakpoint(sm) {
                  right: 8.5em;
                  top: 29em;
              } 

              svg {
                  height: 4.5em; 
                  width: 4.5em; 
              }
          }

          &:nth-child(2){
              position:absolute;
              left: 1em;
              top: 8em;
              z-index: 2;
              @include breakpoint(sm) {
                  left: 8em;
                  top: 9em;
              } 

              svg {
                  height: 4.5em; 
                  width: 4.5em; 
              }
          }

          &:nth-child(3){
              position:absolute;
              left: 16em;
              bottom: 8em;
              z-index: 2;
              @include breakpoint(sm) {
                  left: 33em;
                  bottom: 5em;
              } 

              svg {
                  height: 4.5em; 
                  width: 4.5em; 
              }
          }

      }
  }
.block_text_accordeon{
  .block_text_accordeon_inner{
    .block-accordion-container {
      .accordeon{
        &.ac {
          background-color: var(--black-300);
          border: 1px solid var(--black-200);
          
          .ac-trigger {  
            color:var(--white-100);            
          }
          
        } 
      }
    }
  }
}
}













  