/*
-------------------------------- 
FONTS THEME
--------------------------------
*/
@font-face {
    font-family: "Bagoss-Medium";
    
    src: url("../fonts/BagossExtendedTRIAL-Medium.eot");
    src: url('../fonts/BagossExtendedTRIAL-Medium.eot?#iefix') format('embedded-opentype'),
         url('../fonts/BagossExtendedTRIAL-Medium.woff2') format('woff2'),
         url('../fonts/BagossExtendedTRIAL-Medium.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: "Bagoss-Regular";
    
    src: url("../fonts/BagossStandardTRIAL-Regular.eot");
    src: url('../fonts/BagossStandardTRIAL-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/BagossStandardTRIAL-Regular.woff2') format('woff2'),
         url('../fonts/BagossStandardTRIAL-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: "Noka-black";
    src: url('../fonts/Noka-black.eot');
    src: url('../fonts/Noka-black.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Noka-black.woff2') format('woff2'),
         url('../fonts/Noka-black.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto-Regular";
    src: url('../fonts/Roboto-Regular.eot');
    src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Roboto-Regular.woff2') format('woff2'),
         url('../fonts/Roboto-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}
