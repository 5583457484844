/*
-------------------------------- 
ERROR PAGE
--------------------------------
*/

#page-404{
svg{
    width: 40em;
    height: 20em;
}
  }













  