/*
-------------------------------- 
TYPOGRAPHY
--------------------------------
*/
:root {
    --font-content: "Bagoss-Regular", "helvetica", "arial", sans-serif;
    --font-sub-title: "Bagoss-Medium", "helvetica", "arial", sans-serif;
    --font-title: "Noka-black", serif;

    /* set base values */
    --text-base-size: 0.9em;
    --text-scale-ratio: 1.3;

    /* type scale */
    --text-xs: 8px;
    --text-sm: 12px;
    --text-md: 14px;
    --text-lg: 16px;
    --text-xl: 22px;
    --text-xxl: 28px;
    --text-xxxl: 32px;

    @include breakpoint(sm) {
        --text-xs: 0.8em;
        --text-sm: calc(1.2em / var(--text-scale-ratio));
        --text-md: calc(1em * var(--text-scale-ratio));
        --text-lg: calc(1.1em * var(--text-scale-ratio) * var(--text-scale-ratio));
        --text-xl: calc(1.2em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));
        --text-xxl: calc(1.5em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));
        --text-xxxl: calc(1.7em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) *var(--text-scale-ratio));
   }

    /* line-height */
    --super-heading-line-height: 1;
    --heading-line-height: 1.2;
    --body-line-height: 1.5;
}

.text--xxxl {
    font-size: var(--text-xxxl);
    font-family: var(--font-title);
    line-height: var(--super-heading-line-height);
    text-transform:uppercase;
    -webkit-font-smoothing: antialiased;
}

h1,
.text--xxl {
    font-size: var(--text-xxl);
    line-height: var(--super-heading-line-height);
    font-family:var(--font-title);
    text-transform:uppercase;
    -webkit-font-smoothing: antialiased;
}

h2,
.text--xl {
    font-size: var(--text-xl);
    line-height: var(--heading-line-height);
    font-family:var(--font-title);
    text-transform:uppercase;
    -webkit-font-smoothing: antialiased;
}

h3,
.text--lg {
    font-size: var(--text-lg);
    font-weight: 900;
    line-height: var(--heading-line-height);
    font-family:var(--font-sub-title);
    -webkit-font-smoothing: antialiased;
}

h4,
.text--md {
    font-size: var(--text-md);
    font-family:var(--font-sub-title);
    -webkit-font-smoothing: antialiased;
    line-height: var(--heading-line-height);
}

.text--sm,
small {
    font-size: var(--text-sm);
    font-family:var(--font-content);
    line-height: var(--body-line-height);
}

.text--xs {
    letter-spacing: 0.02em;
    font-size: var(--text-xs);
    font-family:var(--font-content);
    line-height: var(--body-line-height);
}

p, li {
    line-height: var(--body-line-height);
    font-size: var(--text-sm);
}

legend {
    font-size: var(--text-xs);
    font-weight: 600;
    border-radius: 0.3rem;
    padding: 0.8em 1em;
    width: fit-content;
    color:var(--black-100);
}

button,a{
    span{
     font-weight: 600; 
    }
}



h5,
p, 
legend {
font-family:var(--font-content);
-webkit-font-smoothing: antialiased;    
}


.font--content{
    font-family: var(--font-content);
}

.font--title{
    font-family: var(--font-title);
}

.font-subtitle{
    font-family: var(--font-subtitle);
}


.uppercase{
    text-transform:uppercase;
}

.content{
   h1{
    font-size: var(--text-xl);
   } 

   h2{
    font-size: var(--text-lg);
   }

   h3{
    font-size: var(--text-md);
   }

   h4{
    font-size: var(--text-sm);
   }
}