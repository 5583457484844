/*
-------------------------------- 
HEADER LAYOUT
--------------------------------
*/

header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    padding: var(--space-sm) 0;
    mix-blend-mode: difference;
    z-index: $header_level;
    transition: padding .4s;

    &.header-light{
        background-color: var(--white-100);
        color:var(--black-100);
        transition: background-color 0.2s ease;
        box-shadow: 0px 0px 30px #00000017;

        .header-logo {
            position: relative;
    
            svg {
                width: auto;
                height: 1.5em;
    
                @include breakpoint(sm) {
                    height: 1.2em;
                } 
    
                path {
                    fill: var(--black-100);
                    transition: fill 0.4s ease;
                }
    
                polygon{
                    fill: var(--black-100);
                    transition: fill 0.4s ease;
                }
            }
    
        }
    }

    &.header-black{
        background-color: var(--black-100);
        color:var(--white-100);
        transition: background-color 0.2s ease;

        .header-logo {
            position: relative;
    
            svg {
                width: auto;
                height: 1.5em;
    
                @include breakpoint(sm) {
                    height: 1.2em;
                } 
    
                path {
                    fill: var(--white-100);
                    transition: fill 0.4s ease;
                }
    
                polygon{
                    fill: var(--white-100);
                    transition: fill 0.4s ease;
                }
            }
    
        }
    }

    #header-nav{
        font-family: var(--font-title);
        text-transform:uppercase;
          
        a,span {
            padding: 0.8em 1em 0.6em 1em;
            background: transparent;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            border-radius: 8px;
            -khtml-border-radius: 8px;

            &.visited{
                background: rgb(144 142 142 / 15%);
                backdrop-filter: blur(5px);
            }

            &.is-visible{
                background: rgb(144 142 142 / 15%);
                backdrop-filter: blur(5px);
            }

            &:hover{
                background: rgb(144 142 142 / 15%);
                backdrop-filter: blur(5px);
            }
        }

        ul{
            li{
                &:last-child{
                    a{
                        background-color: var(--white-100);
                        color:var(--black-100);
                    }
                }
            }
        }
    }

    @include breakpoint(sm) {
        padding: var(--space-lg) 0;
    }

    
    &:before {
        content: "";
        @include size(100%, 100%);
        @include absolute();
        background: var(--primary_grey);
        transform: translateY(-100%);
        transition: transform 0.8s $easeCustom;
    }

    .header-logo {
        position: relative;

        svg {
            width: auto;
            height: 1.7em;

            @include breakpoint(sm) {
                height: 1.2em;
            } 

            path {
                fill: var(--white-100);
                transition: fill 0.4s ease;
            }

            polygon{
                fill: var(--white-100);
                transition: fill 0.4s ease;
            }
        }

    }




    /*
    -------------------------------- 
    Main Header nav
    --------------------------------
    */
    .header-container {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: color 0.24s ease;

        #header-nav {
            display: none;
            

            ul {
                display: flex;

                li {
                    &:not(:last-child) {
                        margin-right: var(--space-md);
                    }
                }
            }

            @include breakpoint(sm) {
                display: flex;

            }

            /*
            -------------------------------- 
            Language Selector
            --------------------------------
            */
            .language-selector {
                margin-left: var(--space-sm);

                .language-item {
                    text-transform: uppercase;

                    &:first-child {
                        margin-right: var(--space-xs);
                    }

                    &.active {
                        opacity: 0.4;
                    }
                }
            }
        }

        #header-contact{
             padding: 12px 14px;;
            background: var(--white-100);
            color:var(--black-100);
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            border-radius: 8px;
            -khtml-border-radius: 8px;
        }
    }

    /*
    -------------------------------- 
    Burger
    --------------------------------
    */
    .burger {
        position: relative;
        cursor: pointer;
        padding: 12px 0 12px 12px;
        display: block;

        div {
            @include size(32px, 1px);
            mix-blend-mode: difference;
            background-color: var(--white-100);
            display: block;
            position: relative;
            margin-bottom: 3px;
            transition: transform 0.64s $easeOutExpo, opacity 0.24s $easeOutExpo, background-color 0.24s ease;
        }

        @include breakpoint(sm) {
            display: none;
        }
    }


}

/*
-------------------------------- 
Nav Fullscreen
--------------------------------
*/

#nav-fullscreen {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: var(--black-100);
    z-index: $navFullscreen_level;
    text-transform: uppercase;
    color: var(--white-100);
    display: block;
    opacity: 0;
    transform:translateX(100%);
    transition: transform 0.8s $easeSmooth;
    pointer-events: none;

    nav {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: var(--space-xxxl);

        ul {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            li {
                text-transform: uppercase;

                .line-up-menu{
                    transform: translateY(100%);
                    transition: transform 0.4s ease;
                    transition-delay: 0.1s;
                }

                &:not(:first-child) {
                    margin-top: 0.4em;
                }
            }

            ul {
                margin:0.4em 0;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                li {
                    text-transform: uppercase;
    
                    &:not(:first-child) {
                        margin-top: 0.5em;
                    }
                }
            }
        }

    }

    .nav-bottom{
        position:absolute;
        bottom:var(--space-lg);

  
    }

    lottie-player{
        position: absolute;
        bottom: 0;
        height: 10em;
        width: 10em;
        right: var(--component-padding);
        bottom: var(--component-padding);
    }

    @include breakpoint(sm) {
        display: none;
    }
}

/*
-------------------------------- 
Body is Menu
--------------------------------
*/

.is-menu {
    header {
        .header-logo {
            svg {
                path {
                    fill: var(--white-100);
                }
            }
        }

        .burger {
            div {
                background-color: var(--white-100);

                &:nth-child(2) {
                    opacity: 0;
                }

                &:first-child {
                    transform: translate3d(0, 4px, 0) rotate3d(0, 0, 1, -45deg);
                }
                &:last-child {
                    transform: translate3d(0, -4px, 0) rotate3d(0, 0, 1, 45deg);
                }
            }
        }
    }

    #nav-fullscreen {
        pointer-events: auto;
        opacity:1;
        transform:translateX(0%);
        transition: transform 0.8s $easeSmooth;
       

        li{
            .line-up-menu{
                transform: translateY(0%);

                @for $c from 1 through 30 {
                    li:nth-child(#{$c}n).line-up-animation-menu.overflow-hidden-line.line-up-menu {
                     transition-delay: #{$c * 0.1}s;
                     }
                }
            }
        }
    }
}

/*
-------------------------------- 
Mega menu
--------------------------------
*/

#megamenu_services{
    display: none;
    @include breakpoint(sm) {
        display: block;
    }
	.megamenu_services_bg{
		height: 100vh;
		position: fixed;
		width: 100vw;
		bottom: 0;
        right: 0;
        left: 0;
		opacity: 0;
		display:none;
		background-color: rgb(0 0 0 / 87%);
		z-index:$megamenu_back_level;
	}

	.megamenu_services_content{
		height: auto;
		width: 100%;
		position:fixed;
        right: 0;
        left: 0;
		transform:translateY(-100%);
		background-color: var(--black-100);
		z-index:$megamenu_level;
		top:0;

        .megamenu_services_container{
            padding-top:var(--space-xxxl);
            padding-bottom:var(--space-xxl);
            display: grid;
            grid-template-columns: repeat(4,1fr);
            gap:var(--space-lg);

            .megamenu_services_inner{
                display: flex;
                position:relative;
                flex-direction: column;
                gap:var(--space-md);
                padding: var(--space-md) var(--space-md);
                height: 20em;
                width: 100%;
                -webkit-border-radius: 8px;
                -moz-border-radius: 8px;
                border-radius: 8px;
                -khtml-border-radius: 8px;
                transition: background 1s ease;
                border: 1px solid var(--black-300);

                &:hover{
                    background:var(--black-300);
                    transition: background 1s ease;
                }

                
                .description{
                    opacity:0.8;
                }

                .bottom{
                    position: absolute;
                    bottom: var(--space-xs);
                    left: var(--space-md);
                    display: flex;
                    width: calc(100% - 2 * var(--space-md));
                    justify-content: space-between;
                    align-content: center;
                    align-items: center;

                    lottie-player{
                        width: 4em;
                        height: 4em;
                    }

                    .plus {
                        background: var(--black-300);
                        display: flex;
                        align-content: center;
                        align-items: center;
                        height: 40px;
                        border-radius: 40px;
                        width: 40px;
                        justify-content: center;
                    }
                }

            }
        }
	}
}