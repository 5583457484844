/*
-------------------------------- 
LAZYLOAD COMPONENT
--------------------------------
*/
.lazy-container {
    position: relative;
    overflow: hidden;

    .placeholder {
        @include absolute();
        @include size(100%, 100%);
        background: var(--white-200);
        opacity:1;
        transition: opacity 0.8s cubic-bezier(.86,0,.07,.995);
        pointer-events: none;
        user-select: none;
        z-index: 1;
    }

    .lazy{
        z-index: initial;
    }

    &.is-load {
        .placeholder {
            opacity:0;
        }
    }
}


@for $c from 1 through 3 {
    .card-article:nth-child(#{$c}n)  > a > .lazy-container > .placeholder {
      transition-delay: #{$c * 0.1}s;   
     }        
  }
