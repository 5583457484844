/*
-------------------------------- 
COLORS
--------------------------------
*/
:root {

    --white-100: #f2f2f2;
    --white-200: #E6E6E6;
    --black-100: #0b0b0b;
    --black-200:#0e0e0e;
    --black-300:#151515;
    --black-400:#323232;
    --grey-100:#E6E6E6;
    --grey-200:#e1dede;
    --grey-300:#c6bfba;

    --asap-pink: #FFAEA8;
    --gut-feeling: #00DA87;
    --kick-off-purple: #B098FC;
    --network-blue: #008DFF;
    --red-fomo: #FF704A;

    --red:var(--red-fomo);
    --pink:var(--asap-pink);
    --blue:var(--network-blue);
    --purple:var(--kick-off-purple);
    --green:var(--gut-feeling);
    --black:var(--black-100);
    --white:var(--white-100);
    --grey:var(--white-200);

    --principal-color:var(--purple);

    --support: mistyrose;

}


[data-theme="dark"]{

    --primary: #000;
    --secondary: #fff;

    --primary_grey: #1f1f1f;
    --secondary_grey: #f7f7f4;

    --support: #505064;

}