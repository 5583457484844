/* -------------------------------- ABOUT PAGE -------------------------------- */

#page-about{
    lottie-player{
        position:absolute;
        z-index:3;
        width: 11em;
        height: 11em;
        right:8em;
        left:initial;
        top:11em;
        transform:rotate(6deg);

        @include breakpoint(sm) {
            position:absolute;
            z-index:3;
            width: 9em;
            height: 9em;
            left:8em;
            right:initial;
            top:10em;
            transform:rotate(-6deg);
        }
    }
}
