/*
-------------------------------- 
MIXINS
--------------------------------
*/
// edit font rendering -> tip: use for light text on dark backgrounds
@mixin fontSmooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// crop top space on text elements - caused by line height
@mixin lhCrop($line-height, $capital-letter: 1) {
  &::before {
    content: "";
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((#{$capital-letter} - #{$line-height}) * 0.5em);
  }
}

// edit text unit on a component level
@mixin textUnit($text-unit) {
  --text-unit: #{$text-unit};
  font-size: var(--text-unit);
}

// --------------------------------

// Spacing

// --------------------------------

// edit space unit on a component level
@mixin spaceUnit($space-unit) {
  --space-unit: #{$space-unit};
}

// --------------------------------

// Reset

// --------------------------------

// reset user agent style
@mixin reset {
  background-color: transparent;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
}

// --------------------------------

// Colors

// --------------------------------

// define HSL color variable
@mixin defineColorHSL($color, $hue, $saturation, $lightness) {
  #{$color}: unquote("hsl(#{$hue}, #{$saturation}, #{$lightness})");
  #{$color}-h: #{$hue};
  #{$color}-s: #{$saturation};
  #{$color}-l: #{$lightness};
}

// return color with different opacity value
@function alpha($color, $opacity) {
  $color: str-replace($color, "var(");
  $color: str-replace($color, ")");
  $color-h: var(#{$color + "-h"});
  $color-s: var(#{$color + "-s"});
  $color-l: var(#{$color + "-l"});
  @return hsla($color-h, $color-s, $color-l, $opacity);
}

// return color with different lightness value
@function lightness($color, $lightnessMultiplier) {
  $color: str-replace($color, "var(");
  $color: str-replace($color, ")");
  $color-h: var(#{$color + "-h"});
  $color-s: var(#{$color + "-s"});
  $color-l: var(#{$color + "-l"});
  @return hsl($color-h, $color-s, calc(#{$color-l} * #{$lightnessMultiplier}));
}

// modify color HSLA values
@function adjustHSLA($color, $hueMultiplier: 1, $saturationMultiplier: 1, $lightnessMultiplier: 1, $opacity: 1) {
  $color: str-replace($color, "var(");
  $color: str-replace($color, ")");
  $color-h: var(#{$color + "-h"});
  $color-s: var(#{$color + "-s"});
  $color-l: var(#{$color + "-l"});
  @return hsla(
    calc(#{$color-h} * #{$hueMultiplier}),
    calc(#{$color-s} * #{$saturationMultiplier}),
    calc(#{$color-l} * #{$lightnessMultiplier}),
    $opacity
  );
}

// replace substring with another string
// credits: https://css-tricks.com/snippets/sass/str-replace-function/
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

// --------------------------------

// Accessibility

// --------------------------------

// hide - content made available only to screen readers
@mixin srHide {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}

// show
@mixin srShow {
  position: static;
  clip: auto;
  clip-path: none;
}

// --------------------------------

// Grid & Layout

// --------------------------------

$grid-columns: 12 !default;

// used to round width to a number with 2 decimal places - used for IE fallback
@function round-width($i) {
  $width: floor(100% * $i * 100 / $grid-columns) / 100;
  @return $width;
}

// used to create the auto-size columns of the grid system (.col class)
@mixin autoSizedColumn {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

// CSS Grid Layout fallback
@mixin gridFallback($gap: null) {
  display: flex;
  flex-wrap: wrap;
  @if ($gap == null) {
    $gap: var(--space-md);
  }

  margin: calc(#{$gap} * -1) 0 0 calc(#{$gap} * -1);

  > * {
    padding: #{$gap} 0 0 #{$gap};
    background-clip: content-box;
  }

  @supports (grid-area: auto) {
    margin: 0;

    > * {
      padding: 0;
      max-width: none !important;
    }
  }
}

@mixin column($width) {
  @if unit($width) != "%" {
    $width: $width * 100%;
  }
  flex-basis: #{$width};
  max-width: #{$width};
}

// --------------------------------

// CSS Triangle

// --------------------------------

@mixin triangle($direction: up, $width: 12px, $color: red) {
  width: 0;
  height: 0;
  border: $width solid transparent;

  @if ($direction == left) {
    border-right-color: $color;
  } @else if($direction == right) {
    border-left-color: $color;
  } @else if($direction == down) {
    border-top-color: $color;
  } @else {
    border-bottom-color: $color;
  }
}

@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin absolute() {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

@mixin background() {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}



// --------------------------------

// Flex alignement

// --------------------------------



@mixin flex-center() {
  display:block;

  @include breakpoint(sm) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }  
}

@mixin flex-center-fix() {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}




@mixin flex-between(){
  display: flex;
  flex-direction: column;
  gap:var(--space-xl);

  @include breakpoint(sm) {
    display: flex;
    align-items: center;
    gap:var(--space-md);
    justify-content: space-between;
    flex-direction: row;
  }  
}


@mixin flex-between-start(){
  display: flex;
  flex-direction: column;
  gap:var(--space-xl);

  @include breakpoint(sm) {
    display: flex;
    align-items: flex-start;
    gap:var(--space-md);
    justify-content: space-between;
    flex-direction: row;
  }  
}

@mixin flex-between-fix(){
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}



@mixin flex-start() {
  display: flex;
  gap: var(--space-lg);
  align-items: start;
  justify-content: center;
  flex-direction: column; 
}






