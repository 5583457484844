/*
-------------------------------- 
Body
--------------------------------
*/
body,
html {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
}

body {
  font-size:1.7vw;
  font-family: var(--font-content);
  background: var(--black-100);
  color: var(--white-100);
  min-height: 100vh;

  @include breakpoint(sm) {
    font-size:1vw;
}

  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: inherit;

  &:before,
  &:after {
    user-select: none;
    pointer-events: none;
  }

  &:focus {
    outline: none !important;
  }
}

a,
body,
button,
div,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
img,
input,
label,
li,
nav,
p,
select,
span,
table,
textarea,
ul {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
}

img,
video {
  object-fit: cover;
  pointer-events: none;
  user-select: none;
}

img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

a {
  text-decoration: none;
  color: inherit;
  height: fit-content;
}

ul {
  li {
    list-style: none;
  }
}

/*
-------------------------------- 
PARTIAL LAYOUT
--------------------------------
*/
.current-menu-item {
  pointer-events: none;

  a {
      opacity: 0.64;
  }
}

.page-wrapper {
  min-height: calc(100vh - ( 100px + var(--space-md) * 2 ));
  position:relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--max-width-xxxl);

  &::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 60px;
    opacity: 0.8;
    bottom:-60px;
    background: linear-gradient(to bottom, rgb(11, 11, 11) 0%, rgba(11, 11, 11, 0.738) 19%, rgba(11, 11, 11, 0.541) 34%, rgba(11, 11, 11, 0.382) 47%, rgba(11, 11, 11, 0.278) 56.5%, rgba(11, 11, 11, 0.194) 65%, rgba(11, 11, 11, 0.126) 73%, rgba(11, 11, 11, 0.075) 80.2%, rgba(11, 11, 11, 0.042) 86.1%, rgba(11, 11, 11, 0.021) 91%, rgba(11, 11, 11, 0.008) 95.2%, rgba(11, 11, 11, 0.002) 98.2%, rgba(11, 11, 11, 0) 100%);
    pointer-events: none;
}


  &.top{
    padding-top: 8.8em;
    @include breakpoint(sm) {
      padding-top: 6em;
    }
  }

}


textarea,
button,
select,
a {
-webkit-tap-highlight-color: rgba(0,0,0,0);
}


/*
————————————————
Grille
————————————————
*/

.grid-support {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	z-index: 9999;
	pointer-events: none;
	user-select: none;
	padding: 0 0;

	.col-support {
		height: 100vh;
		border-left: 1px solid rgb(168 180 209/4%);
		border-right: 1px solid rgb(168 180 209/4%);
		background: rgb(168 180 209/4%);
	}
}


.grid-4{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  row-gap:var(--space-xl);
  column-gap: var(--space-lg);

  @include breakpoint(sm) {
      grid-template-columns: repeat(4,1fr);
  }
}

.grid-3{
  display: grid;
  grid-template-columns: repeat(1,1fr);
  row-gap:var(--space-xl);
  column-gap: var(--space-lg);

  @include breakpoint(sm) {
      grid-template-columns: repeat(3,1fr);
  }
}

.grid-2{
  display: grid;
  grid-template-columns: repeat(1,1fr);
  row-gap:var(--space-lg);
  column-gap: var(--space-lg);

  @include breakpoint(sm) {
      grid-template-columns: repeat(2,1fr);
  }
}


.grid-1{
  display: grid;
  grid-template-columns: repeat(1,1fr);
  row-gap:var(--space-lg);
  column-gap: var(--space-lg);
}


/*
————————————————
Background color
————————————————
*/

[data-bg="white"]{
	background:var(--white-100);
	color:var(--black-100);
	max-width: var(--max-width-xxxl);
  margin: -1em auto auto auto;
  padding-bottom:1em;
  position:relative;
  z-index:1;
}

[data-bg="black"]{
	background:var(--black-100);
	color:var(--white-100);
	max-width: var(--max-width-xxxl);
  margin: auto;
  position:relative;
  padding-bottom:1em;
  z-index:1;
}

[data-bg="grey"]{
	background:var(--white-200);
	color:var(--black-100);
	max-width: var(--max-width-xxxl);
  margin: -1em auto auto auto;
  position:relative;
  padding-bottom:1em;
  z-index:1;
}

[data-bg="purple"]{
	background:var(--kick-off-purple);
	color:var(--black-100);
	max-width: var(--max-width-xxxl);
  margin: -1em auto auto auto;
  position:relative;
  padding-bottom:1em;
  z-index:1;
}

[data-bg="blue"]{
	background:var(--network-blue);
	color:var(--black-100);
	max-width: var(--max-width-xxxl);
  margin: -1em auto auto auto;
  position:relative;
  padding-bottom:1em;
  z-index:1;
}

[data-bg="green"]{
	background:var(--gut-feeling);
	color:var(--black-100);
	max-width: var(--max-width-xxxl);
  margin: -1em auto auto auto;
  position:relative;
  padding-bottom:1em;
  z-index:1;
}

[data-bg="red"]{
	background:var(--red-fomo);
	color:var(--black-100);
	max-width: var(--max-width-xxxl);
  margin: -1em auto auto auto;
  position:relative;
  padding-bottom:1em;
  z-index:1;
}

[data-bg="pink"]{
	background:var(--asap-pink);
	color:var(--black-100);
	max-width: var(--max-width-xxxl);
  
  position:relative;

  z-index:1;
}

[data-cursor-scale]{
	cursor:pointer;
}

/*
————————————————
Border radius
————————————————
*/

.border-radius-top{
	border-top-left-radius: 0.5rem;
	border-top-right-radius: 0.5rem;

  @include breakpoint(sm) {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
}

.border-radius-bottom{
	border-bottom-left-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;

  @include breakpoint(sm) {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
}


/*
————————————————
Text align
————————————————
*/

.text-center{
    text-align:center;
}

.text-left{
    text-align:left;
}

.text-right{
    text-align:right;
}

.uppercase{
    text-transform:uppercase;
}




/*
————————————————
Tag
————————————————
*/
.purple-tag {
  padding: 0.3em 1.1em;
  background: var(--purple);
  color: black;
  border: 1px solid var(--black-100);
  border-radius: 60px;
}

.red-tag {
  padding: 0.3em 1.1em;
  background: var(--red);
  color: black;
  border: 1px solid var(--black-100);
  border-radius: 60px;
}

.blue-tag {
  padding: 0.3em 1.1em;
  background: var(--blue);
  color: black;
  border: 1px solid var(--black-100);
  border-radius: 60px;
}

.pink-tag {
  padding: 0.3em 1.1em;
  background: var(--pink);
  color: black;
  border: 1px solid var(--black-100);
  border-radius: 60px;
}

.green-tag {
  padding: 0.3em 1.1em;
  background: var(--green);
  color: black;
  border: 1px solid var(--black-100);
  border-radius: 60px;
}
/*
————————————————
Label 
————————————————
*/

.green-label{
    padding: 0.1em 0.2em;
    border-radius: 0.4em;
    border:1px solid var(--black-100);
    background-color: var(--gut-feeling);
}

.pink-label{
    padding: 0.1em 0.2em;
    border-radius: 0.4em;
    border:1px solid var(--black-100);
    background-color: var(--asap-pink);
}

.grey-label{
  padding: 0.1em 0.2em;
  border-radius: 0.4em;
  border:1px solid var(--black-100);
  background-color: var(--asap-pink);
}

.red-label{
    padding: 0.1em 0.2em;
    border-radius: 0.4em;
    border:1px solid var(--black-100);
    background-color: var(--red-fomo);
}

.purple-label{
    padding: 0.1em 0.2em;
    border-radius: 0.4em;
    border:1px solid var(--black-100);
    background-color: var(--kick-off-purple);
}

.blue-label{
    padding: 0.1em 0.2em;
    border-radius: 0.4em;
    border:1px solid var(--black-100);
    background-color: var(--network-blue);
}


/*
————————————————
Rotation
————————————————
*/

.rotate-4{
  position: relative;
  height: auto;
  display: inline-block;
  margin-top: -10px;
  width: auto;
  transform: rotate(4deg);
}

.rotate--6{
  position: relative;
  height: auto;
  display: inline-block;
  margin-top: -10px;
  width: auto;
  transform: rotate(-6deg);
}

.rotate--4{
  position: relative;
  height: auto;
  display: inline-block;
  margin-top: -10px;
  width: auto;
  transform: rotate(-4deg);
}

.rotate-4{
  position: relative;
  height: auto;
  display: inline-block;
  margin-top: -10px;
  width: auto;
  transform: rotate(4deg);
}

.rotate-6{
  position: relative;
  height: auto;
  display: inline-block;
  margin-top: -10px;
  width: auto;
  transform: rotate(6deg);
}


/*
————————————————
Overflow hidden
————————————————
*/

.overflow-hidden-line {
    overflow: hidden;
}

.line-up-animation{
  .line-up{
    transform: translateY(100%);
    opacity:0;
  }
}


/*
————————————————
Cursor
————————————————
*/

.cursors{
  display:none;
  @include breakpoint(sm) {
    display:initial;
}

.cursors_inner {
	position: fixed;
	width: 0px;
	background: var(--principal-color);
	height: 0px;
	border-radius: 100px;
	z-index:$header_level;
	cursor:pointer;
	pointer-events: none;
	transform: translate(-50%, -50%);
  display:flex;
  align-items: center;
  justify-content: center;

    svg{
      width: 40%;
    }
    
 }
}
  
/*
————————————————
Curtain transition
————————————————
*/

.curtain-one{
	position:fixed;
	height: 100vh;
	width: 100vw;
	transform:translateY(100%);
	position:fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top:0;
	background:var(--black-100);
	bottom:0;
  z-index:$curtain_level_one;
}


/*
————————————————
Line
————————————————
*/

.line{
  height:1px;
  background-color: #E4E3E3;
  width: 100%;
  transform-origin: left;
}

/*
————————————————
Padding-top
————————————————
*/

.padding-top-xxxxs {
  padding-top: var(--space-xxxxs);
}
.padding-top-xxxs {
  padding-top: var(--space-xxxs);
}
.padding-top-xxs {
  padding-top: var(--space-xxs);
}
.padding-top-xs {
  padding-top: var(--space-xs);
}
.padding-top-sm {
  padding-top: var(--space-sm);
}
.padding-top-md {
  padding-top: var(--space-md);
}
.padding-top-lg {
  padding-top: var(--space-lg);
}
.padding-top-xl {
  padding-top: var(--space-xl);
}
.padding-top-xxl {
  padding-top: var(--space-xxl);
}
.padding-top-xxxl {
  padding-top: var(--space-xxxl);
}
.padding-top-xxxxl {
  padding-top: var(--space-xxxxl);
}
.padding-top-0 {
  padding-top: 0;
}
.padding-top-component {
  padding-top: var(--component-padding);
}


/*
————————————————
Padding-bottom
————————————————
*/

.padding-bottom-xxxxs {
  padding-bottom: var(--space-xxxxs);
}
.padding-bottom-xxxs {
  padding-bottom: var(--space-xxxs);
}
.padding-bottom-xxs {
  padding-bottom: var(--space-xxs);
}
.padding-bottom-xs {
  padding-bottom: var(--space-xs);
}
.padding-bottom-sm {
  padding-bottom: var(--space-sm);
}
.padding-bottom-md {
  padding-bottom: var(--space-md);
}
.padding-bottom-lg {
  padding-bottom: var(--space-lg);
}
.padding-bottom-xl {
  padding-bottom: var(--space-xl);
}
.padding-bottom-xxl {
  padding-bottom: var(--space-xxl);
}
.padding-bottom-xxxl {
  padding-bottom: var(--space-xxxl);
}
.padding-bottom-xxxxl {
  padding-bottom: var(--space-xxxxl);
}
.padding-bottom-0 {
  padding-bottom: 0;
}
.padding-bottom-component {
  padding-bottom: var(--component-padding);
}


/*
————————————————
Margin-top
————————————————
*/

.margin-top-xxxxs {
  margin-top: var(--space-xxxxs);
}
.margin-top-xxxs {
  margin-top: var(--space-xxxs);
}
.margin-top-xxs {
  margin-top: var(--space-xxs);
}
.margin-top-xs {
  margin-top: var(--space-xs);
}
.margin-top-sm {
  margin-top: var(--space-sm);
}
.margin-top-md {
  margin-top: var(--space-md);
}
.margin-top-lg {
  margin-top: var(--space-lg);
}
.margin-top-xl {
  margin-top: var(--space-xl);
}
.margin-top-xxl {
  margin-top: var(--space-xxl);
}
.margin-top-xxxl {
  margin-top: var(--space-xxxl);
}
.margin-top-xxxxl {
  margin-top: var(--space-xxxxl);
}
.margin-top-auto {
  margin-top: auto;
}
.margin-top-0 {
  margin-top: 0;
}


/*
————————————————
Margin-bottom
————————————————
*/

.margin-bottom-xxxxs {
  margin-bottom: var(--space-xxxxs);
}
.margin-bottom-xxxs {
  margin-bottom: var(--space-xxxs);
}
.margin-bottom-xxs {
  margin-bottom: var(--space-xxs);
}
.margin-bottom-xs {
  margin-bottom: var(--space-xs);
}
.margin-bottom-sm {
  margin-bottom: var(--space-sm);
}
.margin-bottom-md {
  margin-bottom: var(--space-md);
}
.margin-bottom-lg {
  margin-bottom: var(--space-lg);
}
.margin-bottom-xl {
  margin-bottom: var(--space-xl);
}
.margin-bottom-xxl {
  margin-bottom: var(--space-xxl);
}
.margin-bottom-xxxl {
  margin-bottom: var(--space-xxxl);
}
.margin-bottom-xxxxl {
  margin-bottom: var(--space-xxxxl);
}
.margin-bottom-auto {
  margin-bottom: auto;
}
.margin-bottom-0 {
  margin-bottom: 0;
}

/*
————————————————
Fil d'ariane
————————————————
*/

#breadcrumbs{
  span{
    color:var(--grey-300);

    &.breadcrumb_last{
      color:var(--black-100);
    }
  }
}


/*
————————————————
margin left/right auto
————————————————
*/

.ml-auto{
  margin-left:0;
  @include breakpoint(sm) {
    margin-left:auto;
} 
}

.mr-auto{
  margin-right:0;
  @include breakpoint(sm) {
    margin-right:auto;
} 
}

.m-auto{
  margin-left:auto;
  margin-right:auto;
}


picture {
  height: 100%;
  width: 100%;
  object-fit: cover;
}




/*
————————————————
Content
————————————————
*/

  .content {

    ul,
    ol {
        list-style-position: outside;
    }

    ul {
        list-style-type: disc;

        li {
            list-style: initial;
            margin-left: 0.8em;

            &::marker{
              color: var(--black-100);
            }
        }
    }

    ol {
        list-style-type: decimal;
    }

    ul li,
    ol li {
        line-height: var(--body-line-height);
    }

    em {
        font-style: italic;
    }

    u {
        text-decoration: underline;
    }

    a {
        position: relative;
        color: var(--secondary_grey);

        &:not(.no-link) {
            &:after {
                content: "";
                position: absolute;
                height: 1px;
                width: 100%;
                background: var(--principal-color);
                bottom: 0;
                left: 0;
                transform: scaleX(1);
                transform-origin: left;
                transition: transform 1.2s $easeSmooth;
            }

            &:hover {
                &:after {
                    transform: scaleX(0);
                    transform-origin: right;
                }
            }
        }
    }
}


/*
————————————————
top des page
————————————————
*/

.hover_megaMenu{cursor: pointer;}

/*
————————————————
Lottie 
————————————————
*/

lottie-player{
  #animation-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}



/*
————————————————
Transtion de page
————————————————
*/

.overlay {
  grid-area: 1/1/2/2;
  position: fixed;
  fill: var(--black-100);
  z-index: 2000;
  pointer-events: none;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}



/*
————————————————
Transtion de page
————————————————
*/

.process_vignette{
  width: 70em;
  height:30em;
  background-color: var(--white-100);
}


.category{
  padding: 0.8em 1em;
  font-weight: 600;
  color: var(--white-100);
  border-radius: 0.3rem;
}


/*
————————————————
Transtion de page
————————————————
*/

::selection {
  background: var(--principal-color);
  color: var(--black-100);
}
::-moz-selection {
  background: var(--principal-color);
  color: var(--black-100);
}


/*
————————————————
404
————————————————
*/

.error{
  height: 100vh;
  width: 100%;
  .error_inner{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
  }
}


/*
————————————————
Lottie player
————————————————
*/

lottie-player {
  width: 90%;
  height: 90%;
  max-width: 90%;
  max-height: 90%;

  @include breakpoint(sm) {
    width: 70%;
    height: 70%;
    max-width: 70%;
    max-height: 70%;
} 
}

.sticker{
  transform:scale(0);
}

