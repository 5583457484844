/*
-------------------------------- 
SPACING REQUIRE
--------------------------------
*/
:root {
    --space-unit: 1em;
}

:root,
* {
    --space-xxxxs: calc(0.025 * var(--space-unit));
    --space-xxxs: calc(1.15 * var(--space-unit));
    --space-xxs: calc(1.275 * var(--space-unit));
    --space-xs: calc(1.4 * var(--space-unit));
    --space-sm: calc(1.6 * var(--space-unit));
    --space-md: calc(1.8 * var(--space-unit));
    --space-lg: calc(2.8 * var(--space-unit));
    --space-xl: calc(5 * var(--space-unit));
    --space-xxl: calc(11 * var(--space-unit));
    --space-xxxl: calc(14 * var(--space-unit));
    --space-xxxxl: calc(16 * var(--space-unit));
    --component-padding: var(--space-lg);

    @include breakpoint(sm) {
        --component-padding: var(--space-xxxl);
        --space-xxxxs: calc(0.025 * var(--space-unit));
        --space-xxxs: calc(0.15 * var(--space-unit));
        --space-xxs: calc(0.275 * var(--space-unit));
        --space-xs: calc(0.4 * var(--space-unit));
        --space-sm: calc(0.6 * var(--space-unit));
        --space-md: calc(0.8 * var(--space-unit));
        --space-lg: calc(1.7 * var(--space-unit));
        --space-xl: calc(4 * var(--space-unit));
        --space-xxl: calc(5.5 * var(--space-unit));
        --space-xxxl: calc(7.5 * var(--space-unit));
        --space-xxxxl: calc(10 * var(--space-unit));
      }  
      
}

.space--xxl {
    font-size: var(--space-xl);

    @include breakpoint(sm) {
        font-size: var(--space-xxl);
    }
}

.space--xl {
    font-size: var(--space-xl);
}

.space--lg {
    font-size: var(--space-lg);
}

.space--md {
    font-size: var(--space-md);
}

.space--sm {
    font-size: var(--space-sm);
}

.space--xs {
    font-size: var(--space-xs);
}
