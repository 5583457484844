/*
-------------------------------- 
FORMS COMPONENT
--------------------------------
*/
.eyesContainer {
  margin-top:-0.09em;
  width: 1em;
  height: auto;
  position:relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

    .eyes {
      width: 0.55em;
      position:absolute;
      box-shadow: 0 0 10px #0000003b;
      height: 0.8em;
      background-color: var(--white-100);
      border-radius: 50%;
      border:0.022em solid var(--black-100);
      overflow: hidden;
      // animation: blink forwards infinite 2s ease-in-out;

      &:nth-child(1){
        left:0;
        z-index:2;
      }

      &:nth-child(2){
        right:0.006em;
        z-index:1;
      }

        .pupil {
          width: 0.15em;
          height: 0.3em;
          background-color: var(--black-100);
          border-radius: 50%;
          position: relative;
        }
    }
}


@keyframes blink {
  0% {
      height: 0.8em;
  }
  35% {
      height: 0.8em;
  }
  45% {
      height: 0em;
  }
  55% {
      height: 0.8em;
  }
  100% {
      height: 0.8em;
  }
}


