/*
-------------------------------- 
DEFAULT COMPONENT
--------------------------------
*/


// Pagination
.pagination-container {
    li {
        .current {
            pointer-events: none;
            opacity: 0.64;
        }
    }
}


// Images Container
.img-landscape,
.img-square,
.img-portrait {
    display: block;
    height: 0;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        outline: 1px solid transparent;
        display: block;
        top: 0;
        left: 0;
        
    }
}



// Images Portrait
.img-landscape {
    --aspect-ratio: 10/9;
    padding-bottom: calc(100%/(var(--aspect-ratio)));
    

    @include breakpoint(sm) {
        --aspect-ratio: 16/9;
    }
}

// Images landscape
.img-portrait {
    padding-bottom: 130%;
}

// Images landscape
.img-square {
    padding-bottom: 80%;
    
}





// Video container
.video-container {
    padding-bottom: 56.25%;
    height: 0;
    background: var(--secondary);

    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}



/* utility classes */
.truncate {
    // truncate text if it exceeds parent
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


